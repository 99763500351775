import React from "react"
import Container from "../../components/layout/Container"
import Layout from "../../components/layout/Layout"
import WideContainer from "../../components/layout/WideContainer"
import MetaData from "../../components/seo/MetaData"
import OrganizationStructuredData from "../../components/seo/OrganizationStructuredData"

interface Props {
  location: any
}

export default function WildernessSafetyPage({ location }: Props) {
  return (
    <Layout>
      <MetaData
        title="A Guide to Wilderness Safety"
        description="Get tips on how to stay safe in the wilderness. Read our practical guide here for national parks."
        pageUrl={location.pathname}
      />
      <OrganizationStructuredData />
      <WideContainer center={true}>
        <div className={`container md:px-2 px-2`}>
          <h1 className="mt-6 md:mt-8 mb-8">Wilderness Safety</h1>
        </div>
      </WideContainer>
      <Container center={true}>
        <p>
          One of the easiest ways to avoid mishaps is to prepare for your trip
          to a national park. This includes investigating the area, weather,
          terrain and your own limitations. Combined with a little common sense
          you will surely be able to enjoy a safe trip to a national park. Here
          is a list of specific advice to follow during a visit to a park:
        </p>
        <h6>Travel with a companion</h6>
        <p>
          Being more than yourself can be important in emergencies. Travel with
          a companion when you have the chance and leave a copy of your
          itinerary with a responsible person.
        </p>
        <h6>Wear appropriate clothing</h6>
        <p>
          Make sure you understand the clothes needed for the season of the
          national park you are planning to visit.
        </p>
        <h6>Be weather wise</h6>
        <p>
          Use common sense when making decisions and factor in the current
          weather conditions.
        </p>
        <h6>Learn basic first aid</h6>
        <p>
          No one can completely avoid injuries. Make sure you know basic
          identification and treatment of illnesses. Carry a basic first aid kit
          and learn how to identify the symptoms of more serious illnesses such
          as heat stroke and dehydration.
        </p>
        <h6>Make camp before dark</h6>
        <p>
          You should only travel during daylight as darkness has caused many
          accidents from falls. Make sure to set up camp away from danger and
          map close terrain during daylight. This way there will be no surprises
          at night.
        </p>
        <h6>Think before you drink</h6>
        <p>
          Most water contain some form of parasites or organisms. Try to stick
          to your own water or use chemical purification.
        </p>
      </Container>
    </Layout>
  )
}
